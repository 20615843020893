"use client";
import Image from "next/image";

import Cookies from "js-cookie";

import { STATIC_ERROR_TEXTS } from "@/shared/lib/constants";
import { ErrorLayout } from "@/shared/ui/Error/ErrorLayout";

import ServerErrorImg from "@/public/img/errors/500.png";

const Error = () => {
  // TODO: locales issue
  const locale = Cookies.get("NEXT_LOCALE");
  const errorTexts =
    locale === "kz" ? STATIC_ERROR_TEXTS.kz : STATIC_ERROR_TEXTS.ru;

  const { text, title, subtitle, linkText } = errorTexts;

  return (
    <ErrorLayout
      text={text}
      title={title}
      subtitle={subtitle}
      linkText={linkText}
      image={
        <Image
          width={280}
          height={160}
          alt="Not found"
          src={ServerErrorImg}
          style={{
            height: "auto",
            maxWidth: "100%",
          }}
        />
      }
    />
  );
};

export default Error;
